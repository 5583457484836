import React from 'react';

const SVG = ({
  style = {},
  fill = '',
  width = '100%',
  className = '',
  viewBox = '0 0 44 38',
  focusable = 'false',
}) => (
  <svg
    fill={fill}
    focusable={focusable}
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M43.3911 25.873L30.2534 2.46181c-.4331-.74551-1.0606-1.36593-1.8188-1.798279-.7582-.432352-1.6201-.66124715-2.4983-.66345169h-7.6354c-.2966-.00283657-.5889.07045819-.8474.21254169-.2585.142083-.4742.347965-.6255.59702-.1486.251159-.2268.536069-.2268.826089 0 .29001.0782.57492.2268.82608L36.7037 37.1573c.147.2552.3609.4677.6197.6158.2588.1481.5533.2263.8532.2268.3029.0025.6009-.0744.8629-.2226.2621-.1482.4786-.3623.627-.62l3.7415-6.6086c.3916-.7206.5949-1.5243.592-2.3399-.003-.8156-.2121-1.6179-.6089-2.3358zm-2.9628 3.1226l-2.2686 4.0478L21.179 3.30442h4.7573c.274.00071.5431.0712.7806.20449.2376.13329.4353.32477.5738.5555L40.4283 27.4756c.1335.2322.2036.4939.2036.76 0 .2662-.0701.5279-.2036.76zm-9.1083 5.7H8.46466c-.2679-.0001-.53133-.0669-.76537-.1942-.23404-.1272-.43095-.3105-.5721-.5327L4.6385 29.7391h21.8734c.449 0 .8797-.1741 1.1972-.4839.3175-.3098.4958-.7301.4958-1.1683 0-.4381-.1783-.8584-.4958-1.1682-.3175-.3099-.7482-.4839-1.1972-.4839H1.6927c-.29882 0-.59231.0773-.850639.2238-.258332.1466-.47231.3573-.620195.6107-.1478859.2534-.22441102.5405-.22180142.8321.00260959.2916.08426102.5773.23665942.8281l4.012386 6.7244c.44625.7032 1.06576 1.2863 1.80208 1.6961.73631.4098 1.56601.6332 2.41347.6499H31.32c.449 0 .8797-.174 1.1972-.4839.3175-.3098.4958-.73.4958-1.1682 0-.4382-.1783-.8584-.4958-1.1683-.3175-.3098-.7482-.4839-1.1972-.4839zM5.07868 24.2374H23.5323c.2966.0028.5889-.0705.8474-.2126.2585-.142.4742-.3479.6255-.597.1486-.2511.2268-.536.2268-.8261 0-.29-.0782-.5749-.2268-.826L15.8969 6.12962c-.1477-.2497-.3598-.45733-.6153-.60226-.2554-.14492-.5452-.22208-.8407-.22382-.2954.00174-.5853.0789-.8407.22382-.2554.14493-.4675.35256-.6153.60226L3.69042 21.7591c-.14859.2512-.22681.5361-.22681.8261 0 .29.07822.5749.22681.8261.14177.2403.34325.442.58586.5863.24261.1444.51855.2268.8024.2398zm9.27762-13.9608l6.2132 10.6564H8.0922l6.2641-10.6564z" />
  </svg>
);

export default SVG;

import React from 'react';
import { Link } from 'gatsby';
import NavItems from '../NavItems/NavItems';
import NavLang from '../NavLang/NavLang';
import { SiteContext } from '../../context/SiteContextProvider';
import useWindowSize from '../../util/hooks/useWindowSize';

const Header = ({ locale, originalPath }) => {
  const { width } = useWindowSize();
  const link = locale === 'pl' ? `/${locale}` : '';
  return (
    <SiteContext.Consumer>
      {(context) => (
        <>
          <header className="header">
            <div className="menu-wrapper">
              <button aria-label="toggle menu" className={`menu-toggle ${context.data.isMenuOpen ? 'open' : ''}`} type="button" onClick={context.menuToggle}>
                <span className="menu-item" />
                <span className="menu-item" />
                <span className="menu-item" />
              </button>
            </div>
            {(context.data.isMenuOpen === true && width <= context.data.mobileBreakpoint) }
            <div className={`overlay ${(context.data.isMenuOpen === true && width <= context.data.mobileBreakpoint) ? 'mobile' : ''}`}>
              <NavLang originalPath={originalPath} />
              <div className="logo-wrapper">
                {(context.data.isMenuOpen === true && width <= context.data.mobileBreakpoint)
                  ? <Link className="logo" aria-label="Sugarly Designs" to={`${link}/`} onClick={context.menuToggle}>Sugarly Designs</Link>
                  : <Link className="logo" aria-label="Sugarly Designs" to={`${link}/`}>Sugarly Designs</Link>}
              </div>
              <NavItems locale={context.data.locale} />
            </div>
          </header>
        </>
      )}
    </SiteContext.Consumer>
  );
};


export default Header;

import React from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import Icon from '../../assets/icons';

const CartPopup = ({ context }) => {
  const {
    cartCount,
    formattedTotalPrice,
  } = useShoppingCart();
  const { animationCartStatus } = context.data;

  let cartStatus = false;
  if (cartCount > 0) {
    cartStatus = true;
  }

  let cartPopUpClass = 'cartPopup-box-hidden';
  if (cartStatus) {
    cartPopUpClass = 'cartPopup-box';
  }

  if (cartStatus && animationCartStatus) {
    cartPopUpClass = 'cartPopup-click';
  }

  return (
    <section className="cartPopup-container">
      <figure className={cartPopUpClass}>
        <div className="cartPopup-subgrid">
          <Icon className="cartPopup-icon" name="Cart" alt="cart" />
          <p className="cartPopup-quantity">
            {cartCount}
          </p>
          {' '}
          <p className="cartPopup-quantity">
            Items
          </p>
        </div>
        <p className="cartPopup-price">
          {formattedTotalPrice}
        </p>

      </figure>
    </section>
  );
};

export default CartPopup;

import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Icon from '../../assets/icons';

const date = new Date();
const currentYear = date.getFullYear();

const Footer = ({ locale }) => {
  const link = locale === 'pl' ? `/${locale}` : '';
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link
          className="footer-item"
          to={`${link}/contact`}
        >
          <FormattedMessage id="footer.contact" />
        </Link>
        <Link
          className="footer-item"
          to={`${link}/legal`}
        >
          <FormattedMessage id="footer.legal" />
        </Link>
        <Link
          className="footer-item"
          to={`${link}/faq`}
        >
          <FormattedMessage id="footer.faq" />
        </Link>
      </div>
      <div className="footer-logo-wrapper">
        <Link
          to={`${link}/`}
        >
          <h2 className="footer-logo">Sugarly Designs</h2>
        </Link>
        <small className="copyright">
          &copy;
          {currentYear}
        </small>
      </div>
      <div className="footer-icons">
        <a className="footer-item icon-wrapper" aria-label="Instagram link" href="https://www.instagram.com/sugarly.designs/" target="_blank" rel="noreferrer noopener">
          <Icon name="Instagram" className="footer__icon" alt="instagram" />
        </a>
        <a className="footer-item icon-wrapper" aria-label="Pinterest link" href="https://www.pinterest.com/sugarly_designs/" target="_blank" rel="noreferrer noopener">
          <Icon name="Pinterest" className="footer__icon" alt="pinterest" />
        </a>
        <a className="footer-item icon-wrapper" aria-label="Behance link" href="https://www.behance.net/sugarly_designs" target="_blank" rel="noreferrer noopener">
          <Icon name="Behance" className="footer__icon" alt="behance" />
        </a>
      </div>
    </footer>
  );
};
Footer.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Footer;

import React from 'react';

const SVG = ({
  style = {},
  fill = '',
  alt = '',
  width = '100%',
  className = '',
  viewBox = '0 0 40 40',
  focusable = 'false',
}) => (
  <svg
    fill={fill}
    focusable={focusable}
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M29.6181 0H10.3806C4.36516 0 0 4.36645 0 10.3819v19.2362C0 35.6335 4.36516 40 10.3806 40h19.2375C35.6335 40 40 35.6335 40 29.6181V10.3819C40 4.36645 35.6335 0 29.6181 0zm7.8013 29.6181c0 4.5935-3.2078 7.8013-7.8013 7.8013H10.3806c-4.59221 0-7.79995-3.2078-7.79995-7.8013V10.3819c0-4.59351 3.20774-7.80125 7.79995-7.80125h19.2375c4.5935 0 7.8013 3.20774 7.8013 7.80125v19.2362z" />
    <path fill={fill} d="M18.9219 21.2839c-.5161-.6787-1.2555-1.1587-2.2142-1.44.7445-.3691 1.2994-.8529 1.6671-1.4516.3677-.5987.5522-1.2375.5522-1.9162 0-.729-.2077-1.4271-.6219-2.0955-.4142-.6683-.9897-1.1625-1.7213-1.4838-.7316-.3213-1.7071-.4839-2.92-.4839H7.70898V28.289h6.05292c1.0116 0 1.8645-.0877 2.5613-.2645.6968-.178 1.2761-.4387 1.738-.7858.462-.3458.8504-.8387 1.1639-1.4774.3136-.64.471-1.3303.471-2.0735 0-.9252-.2581-1.7252-.7742-2.4039zm-9.11227-2.2v-4.7962h3.17287c1.1407 0 1.9458.0736 2.4155.2207.4697.1484.8297.4219 1.0826.8232.2529.4026.3794.8658.3794 1.3923 0 .5561-.1355 1.018-.4065 1.3858-.271.3677-.6735.6322-1.2077.791-.4039.1225-1.0723.1845-2.0026.1845H9.80963v-.0013zm7.39747 5.9406c-.2104.3871-.4775.68-.8013.8839-.3252.2013-.7291.3458-1.2129.4323-.2736.0503-.751.0774-1.4297.0774H9.80963v-5.4594h3.67097c1.0116 0 1.7845.0878 2.3226.2658.538.1755.9587.4839 1.2619.92.3032.4362.4555.951.4555 1.5433 0 .5058-.1045.9496-.3135 1.3367zM32.2907 22.5212c0-1.8993-.4877-3.3716-1.4619-4.4193-.9755-1.0503-2.231-1.5729-3.769-1.5729-1.5884 0-2.8787.5342-3.8762 1.6026-.9961 1.0696-1.4941 2.5703-1.4941 4.5058 0 1.8696.4929 3.3225 1.4774 4.36.9871 1.0348 2.32 1.5522 4.0038 1.5522 1.3342 0 2.431-.3251 3.2917-.9742.8593-.6503 1.4464-1.5638 1.7638-2.7406l-2.0142-.2465c-.2954.822-.6954 1.422-1.2038 1.7975-.5045.3754-1.1136.5625-1.8284.5625-.96 0-1.7613-.3354-2.4052-1.0064-.6413-.6723-.9974-1.64-1.071-2.9019h8.5768c.0078-.231.0103-.4039.0103-.5188zm-8.4787-1.0864v-.0013c.0633-1.0039.4026-1.8039 1.0104-2.4039.6116-.5987 1.3651-.8993 2.258-.8993.9897 0 1.7949.3768 2.4155 1.1277.4026.4839.649 1.2091.7368 2.1768H23.812zM30.2491 13.8323h-6.5136l-.0167 1.6026h6.5419l-.0116-1.6026z" />

  </svg>
);

export default SVG;

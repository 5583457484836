import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FormattedMessage } from "gatsby-plugin-intl";
import { SiteContext } from "../../context/SiteContextProvider";
import useWindowSize from "../../util/hooks/useWindowSize";

// TODO: Fix links so they work with locale

const Nav = ({ locale }) => {
  const { width } = useWindowSize();
  const link = locale === "pl" ? `/${locale}` : "";
  return (
    <SiteContext.Consumer>
      {(context) => (
        <nav
          className={`nav ${
            context.data.isMenuOpen === true &&
            width <= context.data.mobileBreakpoint
              ? "visible"
              : ""
          }`}
        >
          <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.home" />
          </Link>
          <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/patterns`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.pattern" />
          </Link>
          {/* <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/character-design`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.characterDesign" />
          </Link> */}
          <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/illustrations`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.illustration" />
          </Link>
          <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/contact`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.contact" />
          </Link>
          {/* <Link
            className="nav-item"
            activeClassName="nav-item-active"
            to={`${link}/blog`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.blog" />
          </Link> */}
          {/* <Link
            className="shop-link"
            activeClassName="nav-item-active-shop"
            to={`${link}/shop`}
            onClick={context.menuToggle}
          >
            <FormattedMessage id="nav.shop" />
          </Link> */}
        </nav>
      )}
    </SiteContext.Consumer>
  );
};

Nav.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Nav;

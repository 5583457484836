import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import { injectIntl } from 'gatsby-plugin-intl';
import { CartProvider } from 'use-shopping-cart';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'gatsby';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { SiteContext } from '../../context/SiteContextProvider';
import '../../style/main.scss';
import CartPopup from '../../components/CartPopup/CartPopup';


const Layout = ({ children, locale, originalPath }) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  const link = locale === 'pl' ? `/${locale}` : '';
  return (
    <CartProvider mode="checkout-session" stripe={stripePromise} currency="USD">

      <SiteContext.Consumer>

        {(context) => (
          <div className="page-wrapper">
            <div className="layout">
              <Header locale={context.data.locale} originalPath={originalPath} />
              <main className="main">{children}</main>
              <Link to={`${link}/cart`} aria-label="cart">
                <CartPopup context={context} />
              </Link>
              <CookieConsent
                cookieName="CookieConsentSG"
                expires={365}
                onAccept={context.acceptCookies}
                enableDeclineButton
                declineButtonText="No thanks"
                onDecline={context.dismissCookies}
                setDeclineCookie
                containerClasses="cookie-container"
                contentClasses="cookie-content"
                buttonStyle={{
                  backgroundColor: '#0d0a0b', color: '#fff', borderRadius: '6px', padding: '1rem 0.8rem', font: 'inherit',
                }}
                declineButtonStyle={{
                  backgroundColor: '#EEF0F2', color: '#0d0a0b', border: '1px solid #0d0a0b', borderRadius: '6px', padding: '1rem 0.8rem', font: 'inherit',
                }}
                style={{
                  backgroundColor: '#EEF0F2',
                }}
              >
                This website uses cookies to enhance the user experience. By continuing to use the site, you agree to the use of cookies.
              </CookieConsent>
              <Footer locale={context.data.locale} />
            </div>
          </div>
        )}
      </SiteContext.Consumer>
    </CartProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default injectIntl(Layout);

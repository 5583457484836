import React from 'react';
import { Link } from 'gatsby';
import { SiteContext } from '../../context/SiteContextProvider';
import useWindowSize from '../../util/hooks/useWindowSize';

// TODO: Use Context for languages?

const NavLang = ({ originalPath }) => {
  const { width } = useWindowSize();
  return (
    <SiteContext.Consumer>
      {(context) => (
        <div className={`nav-lang ${(context.data.isMenuOpen === true && width <= context.data.mobileBreakpoint) ? 'visible' : ''}`}>
          <button type="button" className="button-locale" onClick={context.switchLanguageEN}>
            <Link
              to={`${originalPath}`}
              className="link-locale"
              activeClassName="active-link-locale"
            >
              EN
            </Link>
          </button>
          <span className="span-locale">|</span>
          <button type="button" className="button-locale" onClick={context.switchLanguagePL}>
            <Link
              to={`/pl${originalPath}`}
              className="link-locale"
              activeClassName="active-link-locale"
            >
              PL
            </Link>
          </button>
        </div>
      )}
    </SiteContext.Consumer>
  );
};


export default NavLang;

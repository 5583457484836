import React from 'react';

const SVG = ({
  style = {},
  fill = '',
  alt = '',
  width = '100%',
  className = '',
  viewBox = '0 0 40 40',
  focusable = 'false',
}) => (
  <svg
    fill={fill}
    focusable={focusable}
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M29.1724 0H10.8276C4.85718 0 0 4.85718 0 10.8276v18.3451C0 35.1428 4.85718 40 10.8276 40h18.3451C35.1428 40 40 35.1428 40 29.1727V10.8276C40 4.85718 35.1428 0 29.1724 0zm8.4826 29.1727c0 4.6771-3.8052 8.4823-8.4826 8.4823H10.8276c-4.67739 0-8.48263-3.8052-8.48263-8.4823V10.8276c0-4.67739 3.80524-8.48263 8.48263-8.48263h18.3451c4.6771 0 8.4823 3.80524 8.4823 8.48263v18.3451z" />
    <path fill={fill} d="M19.9997 9.06299c-6.0309 0-10.9372 4.90631-10.9372 10.93721s4.9063 10.9372 10.9372 10.9372c6.0309 0 10.9372-4.9063 10.9372-10.9372 0-6.0309-4.9063-10.93721-10.9372-10.93721zm0 19.52941c-4.7376 0-8.5922-3.8544-8.5922-8.5922 0-4.7376 3.8546-8.5922 8.5922-8.5922 4.7378 0 8.5922 3.8546 8.5922 8.5922 0 4.7378-3.8544 8.5922-8.5922 8.5922zM31.1986 5.17847c-1.7822 0-3.2318 1.44989-3.2318 3.23181 0 1.78222 1.4496 3.23212 3.2318 3.23212 1.7822 0 3.2321-1.4499 3.2321-3.23212 0-1.78223-1.4499-3.23181-3.2321-3.23181zm0 4.11865c-.4889 0-.8868-.39795-.8868-.88684 0-.4892.3979-.88684.8868-.88684.4892 0 .8872.39764.8872.88684 0 .48889-.398.88684-.8872.88684z" />
  </svg>
);

export default SVG;

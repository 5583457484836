import React from 'react';

const SVG = ({
  style = {},
  fill = '',
  width = '100%',
  className = '',
  viewBox = '0 0 496 496',
  focusable = 'false',
}) => (
  <svg
    fill={fill}
    focusable={focusable}
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M365.2 0H130.752C57.44 0 0 57.456 0 130.784v234.448C0 438.56 57.44 496 130.752 496H365.2c73.344 0 130.8-57.44 130.8-130.784V130.784C496 57.456 438.544 0 365.2 0zM464 365.216c0 56.304-42.464 98.768-98.8 98.768H130.752C74.448 463.984 32 421.536 32 365.216V130.784C32 74.464 74.448 32 130.752 32H365.2c56.336 0 98.8 42.464 98.8 98.784v234.432z" />
    <path fill={fill} d="M255.072 101.472c-82.64 0-124.304 57.344-124.304 105.152 0 28.96 11.328 54.704 35.6 64.32 1.024.4 2.032.608 2.976.608 2.72 0 4.912-1.664 5.744-4.832.816-2.944 2.704-10.384 3.568-13.52 1.152-4.224.72-5.68-2.496-9.36-7.008-8-11.504-18.352-11.504-32.992 0-42.56 32.928-80.624 85.664-80.624 46.704 0 72.368 27.616 72.368 64.496 0 48.528-22.192 89.504-55.136 89.504-18.224 0-31.824-14.576-27.456-32.432 5.232-21.328 15.376-44.336 15.376-59.712 0-13.792-7.664-25.264-23.472-25.264-18.624 0-33.568 18.608-33.568 43.568 0 15.872 5.552 26.64 5.552 26.64s-19.008 78.048-22.352 91.728c-6.656 27.216-1.008 60.592-.528 63.952.16 1.2 1.184 1.856 2.24 1.856.704 0 1.424-.288 1.888-.896 1.728-2.144 23.872-28.624 31.408-55.088 2.16-7.488 12.24-46.256 12.24-46.256 6.048 11.152 23.712 20.96 42.512 20.96 55.904 0 93.84-49.296 93.84-115.328-.016-49.968-43.712-96.48-110.16-96.48z" />
  </svg>
);

export default SVG;

import React from 'react';

import ArtStation from './src/ArtStation';
import Behance from './src/Behance';
import Instagram from './src/Instagram';
import Cart from './src/Cart';
import Tick from './src/Tick';
import Close from './src/Close';
import Pinterest from './src/Pinterest';


const Icon = (props) => {
  switch (props.name) {
    case 'ArtStation':
      return <ArtStation {...props} />;
    case 'Behance':
      return <Behance {...props} />;
    case 'Instagram':
      return <Instagram {...props} />;
    case 'Cart':
      return <Cart {...props} />;
    case 'Tick':
      return <Tick {...props} />;
    case 'Close':
      return <Close {...props} />;
    case 'Pinterest':
      return <Pinterest {...props} />;
    default:
  }
};

export default Icon;

import React from 'react';

const SVG = ({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  viewBox = '0 0 409.806 409.806',
  focusable = 'false',
}) => (
  <svg
    fill={fill}
    focusable={focusable}
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="m228.929 205.01 175.667-175.667c6.78-6.548 6.968-17.352.42-24.132s-17.352-6.968-24.132-.42c-.142.137-.282.277-.42.42l-175.668 175.667-175.667-175.668c-6.78-6.548-17.584-6.36-24.132.42-6.388 6.614-6.388 17.099 0 23.713l175.667 175.667-175.667 175.667c-6.663 6.664-6.663 17.468 0 24.132 6.664 6.662 17.468 6.662 24.132 0l175.667-175.667 175.667 175.667c6.78 6.548 17.584 6.36 24.132-.42 6.387-6.614 6.387-17.099 0-23.712z" />
  </svg>
);

export default SVG;
